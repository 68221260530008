var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c("c-table", {
              ref: "table",
              attrs: {
                title: "유해인자별 개선목록",
                columns: _vm.grid.columns,
                gridHeight: _vm.gridheight,
                data: _vm.grid.data,
                columnSetting: false,
                usePaging: false,
                filtering: false,
                hideBottom: true,
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "customCol"
                        ? [
                            _c(_vm.imprComponent, {
                              tag: "component",
                              attrs: {
                                col: col,
                                props: props,
                                inputEditable: false,
                                isImmShow: true,
                                ibmTaskTypeCd: "ITT0000175",
                                ibmTaskUnderTypeCd: "ITTU000200",
                              },
                              on: { imprChange: _vm.imprChange },
                            }),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-table",
              {
                ref: "gridImpr",
                attrs: {
                  title: "유해인자외 개선 목록",
                  columns: _vm.gridImpr.columns,
                  data: _vm.gridImpr.data,
                  gridHeight: _vm.gridheight2,
                  usePaging: false,
                  filtering: false,
                  columnSetting: false,
                },
                on: { linkClick: _vm.linkClick2 },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable &&
                        !_vm.disabled &&
                        _vm.data.workMeasurementPlanId
                          ? _c("c-btn", {
                              attrs: {
                                label: "LBLIMPRREQUEST",
                                showLoading: false,
                                icon: "add",
                              },
                              on: { btnClicked: _vm.addImpr },
                            })
                          : _vm._e(),
                        _vm.editable &&
                        !_vm.disabled &&
                        _vm.data.workMeasurementPlanId
                          ? _c("c-btn", {
                              attrs: {
                                label: "즉시조치",
                                showLoading: false,
                                icon: "add",
                              },
                              on: { btnClicked: _vm.addiimImpr },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }